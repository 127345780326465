<template>
  <section v-if="data" class="banner" v-bind:style="{ backgroundImage: 'url(' + data.images + ')' }">
    <div class="container" v-if="data">
      <div class="text_banner">
        <div class="title_sub">
          <span style="text-shadow: rgb(0 0 0) 2px 2px 13px;">{{data.category}}</span>
        </div>
        <h1 style="white-space: pre-wrap;text-shadow: rgb(0 0 0) 2px 2px 13px;" v-html="data.title"></h1>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    data: null
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
