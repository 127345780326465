<template>
  <section class="amber-contact" id="ambercontact">
    <div class="amber-contact__content" data-aos="fade-up" v-if="language && Object.keys(language).length > 0">
      <h4 class="contact__intro info-hr">{{language["home::contactus"][locale]}}</h4>
      <div class="check-left">
        <h2 class="contact__title">{{language["home::pleasecontactus"][locale]}}</h2>
        <h3 class="contact__des">{{language[hay][locale]}}</h3>
        <div class="contact__method">
          <div class="m-phone box">
            <div class="box__icon">
              <img src="../../assets/img/icons/phone-bottom.svg" alt="" />
            </div>
            <div class="box__content">
              <p class="s-title">{{language["menu::contact"][locale]}}</p>
              <p class="s-des">{{info?.phone}}</p>
            </div>
          </div>
          <div class="m-email box">
            <div class="box__icon">
              <img src="../../assets/img/icons/mail-bottom.svg" alt="" />
            </div>
            <div class="box__content">
              <p class="s-title">Email</p>
              <p class="s-des">{{info?.email}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="check-right" v-if="language && Object.keys(language).length > 0">
        <form class="contact__form" @submit.prevent="submitContact" ref="formContact">
          <div class="form-name form-field">
            <input type="text" :placeholder='language["home::name"][locale]'  v-model="form.name" required/>
          </div>
          <div class="form-phone form-field">
            <input type="text" :placeholder='language["home::phonenb"][locale]'  v-model="form.phone" required/>
          </div>
          <div class="form-email form-field">
            <input type="text" placeholder="Email *"  v-model="form.email" required/>
          </div>
          <div class="form-content form-field">
            <input type="text" :placeholder='language["home::ntt"][locale]' v-model="form.content" required/>
          </div>
          <div class="form-submit">
            <p class="note-requile">(*) {{language["home::required"][locale]}}</p>
            <button type="submit" class="button-send" @click="submitContact">
              <p>{{language["other::send"][locale]}}</p>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="amber-contact__map" data-aos="fade-up">
      <div class="content">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3813.6602993888737!2d106.98058211529631!3d17.08926111583181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3140c7b17160bd67%3A0x89916776c97c3419!2zS0NOIEFtYmVyIFTDonkgQuG6r2MgSOG7kyBYw6E!5e0!3m2!1svi!2s!4v1661102773934!5m2!1svi!2s"
          width="600"
          height="450"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
    <div class="amber-contact__bg">
      <h1 class="bg-text">
        {{ titleBottom == null ? "CONTACT US" : titleBottom }}
      </h1>
    </div>
  </section>
</template>
<script>
import ContactService from "@/services/ContactService";
import { mapGetters } from 'vuex';
// import CommonSerive from "@/services/CommonSerive";

export default {
  components: {},
  props: {
    titleBottom: null,
    language: null
  },
  data() {
    return {
      hay: "home::welookforward",
      form: {
        email: "",
        phone: "",
        name: "",
        content: ""
      },
      // language: [],
    };
  },
  mounted() {
    // this.dataHome();
  },
  computed: {
    ...mapGetters(["info", "locale"]),
  },
  watch: {
    // language: function () {
    //   this.$forceUpdate();
    // },
  },
  // created() {
  //   this.dataHome();
  // },
  methods: {
    submitContact() {
      // let formDatas = new FormData();
      // for (var key in this.form) {
      //   formDatas.append(key, this.form[key]);
      // }
      ContactService.sendContact(this.form)
        .then(() => {
          // this.$refs.formContact.reset();
          this.resetForm();
          this.$toast.fire({
            icon: "success",
            title: "Gửi Thành Công",
          });
          console.log(this.form);
        })
        .catch(() => {
          this.$toast.fire({
            icon: "error",
            title: "Gửi Thất Bại",
          });
        });
    },

    resetForm() {
      this.form.email = "";
       this.form.phone = "";
       this.form.name = "";
       this.form.content = "";
    },
    // async dataHome() {
    //   await CommonSerive.getLang()
    //     .then((resp) => {
    //       this.language = resp.data.data
    //     })
    //     .catch(() => {});
    // },
  }
};
</script>
