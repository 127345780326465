<template>
  <div class="main-investor bg-gray">
    <BannerTop :data="dataBanner" />
    <div class="container container-investor bg-line">
      <div class="container-investor__content" ref="investor0">
        <!-- <p v-if="dataR" v-html="dataR[locale].content"></p> -->
        <p v-if="dataR"></p>
        <h1 class="container__title" v-html="dataBanner.content">
        </h1>
        <div class="container__content investor-content">
          <p v-html="dataBanner.textLeft" style="white-space: pre-wrap !imporant;">
          </p>
          <p v-html="dataBanner.textRight" style="white-space: pre-wrap !imporant;">
          </p>
        </div>
      </div>
      <div class="container__bg">
        <img src="../assets/images/bg21.png" alt="" />
      </div>
      <div class="list-number investor-show" v-if="language && Object.keys(language).length > 0">
        <div class="item" v-for="(item, index) in listNumber" :key="index">
          <h2 class="item__number">
            <span class="plus">+</span>
            <span class="investor-number">{{ item.number }}</span>
          </h2>
          <p class="item__title" v-html="language[item.title][locale]"></p>
        </div>
      </div>
      <div class="project" ref="investor1" v-if="language && Object.keys(language).length > 0">
        <div class="project_left">
          <h1 class="project__title">{{language["other::implemented2"][locale]}}</h1>
          <h2 class="project__des">
            {{language["other::across10provinces2"][locale]}}
          </h2>
          <div class="list-project" v-if="listProject?.length > 0">
            <div class="item" v-for="(item, index) in listProject" :key="index">
              <h4 class="item__address">{{ item[locale]?.title }}</h4>
              <div class="item__address-sub">
                <p v-html="item[locale]?.content"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="project_right">
          <img :src="mapImgae" alt="" />
        </div>
      </div>
    </div>
    <Contact :language="language"/>
  </div>
</template>

<script>
// import { mapMutations } from "vuex";
import BannerTop from "@/components/common/BannerTop.vue";
import Contact from "@/components/home/Contact.vue";
import { conuterNumber4 } from "../utils/import";
import InvestorService from "@/services/InvestorService";
// import { autoScroll } from "../utils/import";
import { mapGetters } from "vuex";
import CommonSerive from "@/services/CommonSerive";

export default {
  components: {
    BannerTop,
    Contact,
  },
  data() {
    return {
      dataBanner: {
        category: "menu::investor",
        title: "Quang Anh nhà phát triển <br> bất động sản chuyên nghiệp",
        des: "",
        images: "",
      },
      dataR: '',
      mapImgae: '',
      listNumber: [
        {
          number: 20,
          title: "other::IMPLEMENTATION",
        },
        {
          number: 3000,
          title: "other::HAEARTH EDGE",
        },
        {
          number: 15000,
          title: "other::TOTALINVESTMENT15",
        },
        {
          number: 100,
          title: "other::INTERNATIONAL20",
        },
      ],
      listProject: [
        {
          address: "THÀNH PHỐ HÀ NỘI",
          content:
            "Chung Cư E2 Chelsea Residence <br> Chung Cư Cao Cấp & DVTM Cổ Linh <br> Toà Nhà Văn Phòng Kết Hợp Dịch Vụ Apex Tower<br>  Khu Du Lịch Sinh Thái Bản Xôi",
        },
        {
          address: "TỈNH VĨNH PHÚC",
          content: "Sân Golf Bàn Long - 73 HA",
        },
        {
          address: "TỈNH BẮC GIANG",
          content: "Sân Golf Amber Hills Golf & Resort - 190 Ha",
        },
        {
          address: "TỈNH QUẢNG TRỊ",
          content: "Khu Công Nghiệp Amber Tây Bắc Hồ Xá - 214,7 HA",
        },
        {
          address: "TỈNH QUẢNG NAM",
          content: "Khu Lưu Trú Và Trải Nghiệm Đồng Dương - 32,156 HA",
        },
        {
          address: "TỈNH KHÁNH HOÀ",
          content:
            "Khu Du Lịch Dốc Lết - Phướng Mai - 152,45 HA <br>Khách Sạn Zenia Nha Trang - 4.200m2",
        },
        {
          address: "TỈNH BÀ RỊA - VŨNG TÀU",
          content: "Khu Công Nghiệp Hắc Dịch - 500 HA",
        },
        {
          address: "TỈNH LÂM ĐỒNG",
          content: "Dự Án Sinh Thái Lâm Đồng - 571,21 HA",
        },
      ],
      language:[]
    };
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    conuterNumber4(".investor-show", ".investor-number");
    this.checkScroll("investor");
    this.getData();
    this.dataHomea();
  },
  watch: {
    "$store.state.subActive": function () {
      let ref = "investor" + this.subActive;
      let el = this.$refs[ref];
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
  },
  computed: {
    ...mapGetters(["locale","subActive"]),
  },
  methods: {
    checkScroll(elem) {
      if (this.subActive >= 0) {
        let ref = elem + this.subActive;
        let el = this.$refs[ref];
        if (this.subActive >= 0) {
          this.scrollEl(el, "");
        }
      }
    },
    scrollEl(el, pointV) {
      let point = pointV ? pointV : "start";
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: point,
        });
      }
    },
    async getData() {
      await InvestorService.getData()
        .then((resp) => {
          console.log(resp.data)
          this.dataR = resp.data.data;
          this.dataBanner.title = this.dataR[this.locale]?.title;
          this.dataBanner.textLeft = this.dataR[this.locale]?.text_left;
          this.dataBanner.textRight = this.dataR[this.locale]?.text_right;
          this.dataBanner.content = this.dataR[this.locale]?.content;
          this.dataBanner.images = this.dataR && this.dataR.image.replace(" ", "%20");

          this.listProject = this.dataR.blocks;
          this.mapImgae = this.dataR.mapvn;
        })
        .catch(() => {});
    },
    async dataHomea() {
      await CommonSerive.getLang()
        .then((resp) => {
          this.language = resp.data.data
          this.dataBanner.category = resp.data.data['menu::investor'][this.locale];
        })
        .catch(() => {});
    },
  },
};
</script>
