import http from "@/http/http-common";
// const resource = "/api/frontend";
class ContactService {
    sendContact(data) {
        return http.post(`add-contact`, data);
    }
    getListContact() {
        return http.get(`/contact`);
    }
    // put(data) {
    //     return http.put(`/abc/`, data);
    // }
    // delete(id) {
    //     return http.delete(`abc/${id}`);
    // }

}

export default new ContactService();
